import { Controller } from '@hotwired/stimulus'
import { useIdle } from 'stimulus-use'

const secondsBeforeWarning = 60 * 60 // 1 hour
const warningLength = 3 * 60 //  3 minutes

export default class extends Controller {
  connect () {
    useIdle(this, { ms: secondsBeforeWarning * 1000 })
  }

  away () {
    if (this.awayStartedAt) return

    this.awayStartedAt = Date.now()
    this.element.querySelector('dialog').showModal()
    this.timeout = setInterval(() => {
      this.updateWarning()
    }, 1000)
  }

  stop () {
    clearInterval(this.timeout)
    this.awayStartedAt = null
  }

  updateWarning () {
    const awayInSeconds = Math.round((Date.now() - this.awayStartedAt) / 1000)
    document.getElementById('dialog-countdown').innerHTML = warningLength - awayInSeconds

    if (awayInSeconds >= warningLength) {
      document.getElementById('idle-logout-button').click()
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import IMask from 'imask'

export default class extends Controller {
  static targets = ['country', 'number']

  connect () {
    this.masks = countryMasks
    this.maskEl = this.initMask()
  }

  initMask () {
    return IMask(this.numberTarget, { mask: this.countryMask() })
  }

  setMask () {
    this.maskEl.updateOptions({ mask: this.countryMask() })
  }

  countryMask () {
    const countryCode = this.countryTarget.value
    let countryMask = this.masks[countryCode]
    if (countryMask === undefined) {
      countryMask = Number
    }
    return countryMask
  }
}

const countryMasks = {
  45: '00 00 00 00',
  47: '00 00 00 00',
  299: '00 00 00'
}

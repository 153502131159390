// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import Trix from 'trix'
import '@rails/actiontext'
import translations from './shared/trix-translations'
import '@oddbird/popover-polyfill'

import './controllers'
import './shared/turbo_stream_animations'
import './shared/custom_turbo_streams'

import { Chart, LineController, LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend, BarController, BarElement, CategoryScale } from 'chart.js'

Chart.register(LineController, LineElement, PointElement, LinearScale, TimeScale, Tooltip, Legend, BarController, BarElement, CategoryScale)

window.$ = window.jQuery = require('jquery')

const translation = translations[document.documentElement.lang]
if (translation) {
  const toolbarButtonsNames = ['attachFiles', 'bold', 'italic', 'strike', 'link', 'unlink', 'urlPlaceholder', 'heading1', 'quote', 'code', 'bullets', 'numbers', 'undo', 'indent', 'outdent', 'redo']
  toolbarButtonsNames.forEach((buttonName) => {
    Trix.config.lang[`${buttonName}`] = translation[`${buttonName}`]
  })
}

Turbo.setConfirmMethod((message) => {
  const dialog = document.getElementById('turbo-confirm')
  dialog.querySelector('p').textContent = message
  dialog.showModal()

  return new Promise((resolve) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm')
    }, { once: true })
  })
})

// Disable number input spinners
window.addEventListener('wheel', (event) => {
  if (event.target.tagName.toLowerCase() === 'input' && event.target.type === 'number') {
    event.preventDefault()
  }
}, { passive: false })

// =============
// = Scrollbar =
// =============
document.addEventListener('turbo:load', function () {
  const $scrollToBottomElements = $('div[data-scroll-to~="bottom"]')
  $.each($scrollToBottomElements, function (index, $el) {
    $el = $($el)
    $el.scrollTop($el.prop('scrollHeight'))
  })
})

// =============
// = Sidebar =
// =============
$(document).on('click', '#menu-toggler', function () {
  $('#sidebar').toggleClass('active')
})

$(document).on('turbo:before-cache', function () {
  $('#sidebar').removeClass('active')
})

$(document).on('click', function (event) {
  const clickOutsideSidebar = !$('#sidebar').get(0).contains(event.target)
  const bottomMenu = $('#mobile-bottom-menu').get(0)
  const inBottomMenu = !!bottomMenu && bottomMenu.contains(event.target)
  const isActive = $('#sidebar').hasClass('active')
  // Check if clicked on a link in sidebar that is replaced with a form by ujs
  // The form is not contained in sidebar since its created at root level
  // https://github.com/rails/jquery-ujs/blob/master/src/rails.js#L213-L232
  const isMethodLink = $(event.target).siblings('[name="_method"]').length > 0

  if (isActive && clickOutsideSidebar && !inBottomMenu && !isMethodLink) {
    event.preventDefault()
    $('#sidebar').removeClass('active')
  }
})
